'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import Button, { ButtonVariant } from '@/components/Button';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const translation = useTranslations('Error');

  return (
    <div className="container flex h-full flex-1 flex-col items-center justify-center ">
      <div className="flex flex-col md:max-w-[75ch]">
        <h2>{translation('title')}</h2>
        <p className="mt-2 text-xl font-semibold">{translation('text')}</p>
        <p className="mt-2 text-pretty text-sm">{error.message}</p>
        <Button
          className="mt-4 w-32 md:w-full md:max-w-xs"
          variant={ButtonVariant.Secondary}
          onClick={() => reset()}
        >
          {translation('button')}
        </Button>
      </div>
    </div>
  );
}
